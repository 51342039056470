<template lang="pug">
tr
  sticky-column.bdr2(
    colspan="1",
    :offset="0",
    style="width: 100px; min-width: 100px"
  )
  sticky-column.bdr2(
    colspan="1",
    :offset="100",
    style="width: 80px; min-width: 80px",
    v-if="category == 'GameLog'"
  )

  th(colspan="1", v-if="category == 'Statistics'")
  th(colspan="2", v-if="category == 'Season' || category == 'LineUp'")
  th(colspan="3", v-if="category == 'PlayerOverView'")
  th(colspan="4", v-if="category == 'GameLog'")

  th(colspan="5")
  th.bdr(colspan="3") 投籃
  th.bdr(colspan="3") 兩分
  th.bdr(colspan="3") 三分
  th.bdr(colspan="3") 罰球
  th.bdr(colspan="2") 籃板
  th(colspan="5")
</template>

<script>
import StickyColumn from "@/components/common/Table/StickyColumn";
export default {
  name: "TopHead",
  props: {
    category: {
      type: String,
      required: true,
    },
  },
  components: {
    StickyColumn,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
</style>
