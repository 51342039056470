<template lang="pug">
.line_up 
  v-container(v-if="false")
    v-row.position-relative
      img.w-100(:src="positionImg")
      v-card.position_card.elevation-0.position-absolute(
        tile,
        outlined,
        style="min-width: 102px; padding: 10px; left: 50%; top: 72px; transform: translateX(-50%)"
      )
        p.text-subtitle.font-weight-bold Center
        a.player 喬楚瑜
        a.player 江均
        a.player 魏嘉豪
      v-card.position_card.elevation-0.position-absolute(
        tile,
        outlined,
        style="min-width: 102px; padding: 10px; left: 60%; top: 50%; transform: translateY(-50%)"
      )
        p.text-subtitle.font-weight-bold Small Forward
        a.player 林哲霆
        a.player 楊承翰
        a.player 蔡紘揚
      v-card.position_card.elevation-0.position-absolute(
        tile,
        outlined,
        style="min-width: 102px; padding: 10px; right: 60%; top: 50%; transform: translateY(-50%)"
      )
        p.text-subtitle.font-weight-bold Power Forward
        a.player 王翊帆
        a.player 莫翔其
        a.player 李沛澄
      v-card.position_card.elevation-0.position-absolute(
        tile,
        outlined,
        style="min-width: 102px; padding: 10px; left: 70%; bottom: -10px"
      )
        p.text-subtitle.font-weight-bold Shooting Guard
        a.player 林哲霆
        a.player 楊承翰
        a.player 蔡紘揚
      v-card.position_card.elevation-0.position-absolute(
        tile,
        outlined,
        style="min-width: 102px; padding: 10px; right: 70%; bottom: -10px"
      )
        p.text-subtitle.font-weight-bold Point Guard
        a.player 林哲霆
        a.player 楊承翰
        a.player 蔡紘揚
  v-container
    v-row.table_row
      v-col#sticky-nav.table_col.table.position-relative.mt-4(cols="12")
        table
          thead.top_head
            top-head(category="LineUp")
          thead
            tr.bottom_head
              sticky-column.bdr2(
                style="width: 120px; min-width: 120px",
                :title="'球員'",
                :offset="0"
              )
              th(
                style="cursor: pointer",
                @click="sortClick('record_matches', 'INFO')",
                :class="{ sort_up: sortValue.type == 'record_matches' && sortValue.order == 'ASC', sort_down: sortValue.type == 'record_matches' && sortValue.order == 'DESC' }"
              )
                span 出賽
                font-awesome-icon.ml-2(:icon="getTableSort('record_matches')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('avg_secs', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_secs' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_secs' && sortValue.order == 'DESC' }"
              )
                span 時間
                font-awesome-icon.ml-2(:icon="getTableSort('avg_secs')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_pts', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_pts' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_pts' && sortValue.order == 'DESC' }"
              )
                span 得分
                font-awesome-icon.ml-2(:icon="getTableSort('avg_pts')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_reb', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_reb' && sortValue.order == 'DESC' }"
              )
                span 籃板
                font-awesome-icon.ml-2(:icon="getTableSort('avg_reb')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_ast', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_ast' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_ast' && sortValue.order == 'DESC' }"
              )
                span 助攻
                font-awesome-icon.ml-2(:icon="getTableSort('avg_ast')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_stl', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_stl' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_stl' && sortValue.order == 'DESC' }"
              )
                span 抄截
                font-awesome-icon.ml-2(:icon="getTableSort('avg_stl')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('avg_blk', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_blk' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_blk' && sortValue.order == 'DESC' }"
              )
                span 阻攻
                font-awesome-icon.ml-2(:icon="getTableSort('avg_blk')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_fgm', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_fgm' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_fgm' && sortValue.order == 'DESC' }"
              )
                span 命中
                font-awesome-icon.ml-2(:icon="getTableSort('avg_fgm')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_fga', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_fga' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_fga' && sortValue.order == 'DESC' }"
              )
                span 出手
                font-awesome-icon.ml-2(:icon="getTableSort('avg_fga')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('avg_fg_percent', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_fg_percent' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_fg_percent' && sortValue.order == 'DESC' }"
              )
                span 命中率
                font-awesome-icon.ml-2(:icon="getTableSort('avg_fg_percent')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_two_pt_fgm', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_two_pt_fgm' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_two_pt_fgm' && sortValue.order == 'DESC' }"
              )
                span 命中
                font-awesome-icon.ml-2(:icon="getTableSort('avg_two_pt_fgm')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_two_pt_fga', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_two_pt_fga' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_two_pt_fga' && sortValue.order == 'DESC' }"
              )
                span 出手
                font-awesome-icon.ml-2(:icon="getTableSort('avg_two_pt_fga')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('avg_two_pt_fg_percent', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_two_pt_fg_percent' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_two_pt_fg_percent' && sortValue.order == 'DESC' }"
              )
                span 命中率
                font-awesome-icon.ml-2(
                  :icon="getTableSort('avg_two_pt_fg_percent')"
                )
              th(
                style="cursor: pointer",
                @click="sortClick('avg_three_pt_fgm', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_three_pt_fgm' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_three_pt_fgm' && sortValue.order == 'DESC' }"
              )
                span 命中
                font-awesome-icon.ml-2(
                  :icon="getTableSort('avg_three_pt_fgm')"
                )
              th(
                style="cursor: pointer",
                @click="sortClick('avg_three_pt_fga', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_three_pt_fga' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_three_pt_fga' && sortValue.order == 'DESC' }"
              )
                span 出手
                font-awesome-icon.ml-2(
                  :icon="getTableSort('avg_three_pt_fga')"
                )
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('avg_three_pt_fg_percent', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_three_pt_fg_percent' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_three_pt_fg_percent' && sortValue.order == 'DESC' }"
              )
                span 命中率
                font-awesome-icon.ml-2(
                  :icon="getTableSort('avg_three_pt_fg_percent')"
                )
              th(
                style="cursor: pointer",
                @click="sortClick('avg_ftm', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_ftm' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_ftm' && sortValue.order == 'DESC' }"
              )
                span 命中
                font-awesome-icon.ml-2(:icon="getTableSort('avg_ftm')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_fta', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_fta' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_fta' && sortValue.order == 'DESC' }"
              )
                span 出手
                font-awesome-icon.ml-2(:icon="getTableSort('avg_fta')")
              th.bdr(
                style="cursor: pointer",
                @click="sortClick('avg_ft_percent', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_ft_percent' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_ft_percent' && sortValue.order == 'DESC' }"
              )
                span 命中率
                font-awesome-icon.ml-2(:icon="getTableSort('avg_ft_percent')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_off_reb', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_off_reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_off_reb' && sortValue.order == 'DESC' }"
              )
                span 進攻
                font-awesome-icon.ml-2(:icon="getTableSort('avg_off_reb')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_def_reb', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_def_reb' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_def_reb' && sortValue.order == 'DESC' }"
              )
                span 防守
                font-awesome-icon.ml-2(:icon="getTableSort('avg_def_reb')")

              th(
                style="cursor: pointer",
                @click="sortClick('avg_tov', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_tov' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_tov' && sortValue.order == 'DESC' }"
              )
                span 失誤
                font-awesome-icon.ml-2(:icon="getTableSort('avg_tov')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_pf', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_pf' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_pf' && sortValue.order == 'DESC' }"
              )
                span 犯規
                font-awesome-icon.ml-2(:icon="getTableSort('avg_pf')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_plus_minus', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_plus_minus' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_plus_minus' && sortValue.order == 'DESC' }"
              )
                span +/-
                font-awesome-icon.ml-2(:icon="getTableSort('avg_plus_minus')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_eff', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_eff' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_eff' && sortValue.order == 'DESC' }"
              )
                span EFF
                font-awesome-icon.ml-2(:icon="getTableSort('avg_eff')")
              th(
                style="cursor: pointer",
                @click="sortClick('avg_at_ratio', 'BOX')",
                :class="{ sort_up: sortValue.type == 'avg_at_ratio' && sortValue.order == 'ASC', sort_down: sortValue.type == 'avg_at_ratio' && sortValue.order == 'DESC' }"
              )
                span A/T
                font-awesome-icon.ml-2(:icon="getTableSort('avg_at_ratio')")

          tbody
            tr(
              v-for="(player, index) in currentPlayerSeasonBoxData",
              :key="index"
            )
              sticky-column.bdl.bdr2(
                style="width: 120px; min-width: 120px; cursor: pointer",
                :offset="0",
                :title="player.info.name",
                :hover="true",
                @click.native="playerClick(player)"
              )

              td {{ player.info.record_matches }}
              td.bdr {{ getMinutes(player.box.avg_secs) }}
              td(
                @click="dataClick(player, 'pts', '得分')",
                :class="[player.box.avg_pts > 0 ? 'link_deep' : 'gray_out']"
              ) {{ player.box.avg_pts.toFixed(1) }}
              td(
                @click="dataClick(player, 'reb', '籃板')",
                :class="[player.box.avg_reb > 0 ? 'link_deep' : 'gray_out']"
              ) {{ player.box.avg_reb.toFixed(1) }}
              td(
                @click="dataClick(player, 'ast', '助攻')",
                :class="[player.box.avg_ast > 0 ? 'link_deep' : 'gray_out']"
              ) {{ player.box.avg_ast.toFixed(1) }}
              td(
                @click="dataClick(player, 'stl', '抄截')",
                :class="[player.box.avg_stl > 0 ? 'link_deep' : 'gray_out']"
              ) {{ player.box.avg_stl.toFixed(1) }}
              td.bdr(
                @click="dataClick(player, 'blk', '阻攻')",
                :class="[player.box.avg_blk > 0 ? 'link_deep' : 'gray_out']"
              ) {{ player.box.avg_blk.toFixed(1) }}
              td(
                @click="dataClick(player, 'fg_m', '投籃命中')",
                :class="[player.box.avg_fgm > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_fgm.toFixed(1) }}
              td(
                @click="dataClick(player, 'fg_a', '投籃出手')",
                :class="[player.box.avg_fga > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_fga.toFixed(1) }}
              td.bdr {{ (player.box.avg_fg_percent * 100).toFixed(1) + '%' }}
              td(
                @click="dataClick(player, 'two_pts_m', '兩分命中')",
                :class="[player.box.avg_two_pt_fgm > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_two_pt_fgm.toFixed(1) }}
              td(
                @click="dataClick(player, 'two_pts_a', '兩分出手')",
                :class="[player.box.avg_two_pt_fga > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_two_pt_fga.toFixed(1) }}
              td.bdr {{ (player.box.avg_two_pt_fg_percent * 100).toFixed(1) + '%' }}
              td(
                @click="dataClick(player, 'three_pts_m', '三分命中')",
                :class="[player.box.avg_three_pt_fgm > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_three_pt_fgm.toFixed(1) }}
              td(
                @click="dataClick(player, 'three_pts_a', '三分出手')",
                :class="[player.box.avg_three_pt_fga > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_three_pt_fga.toFixed(1) }}
              td.bdr {{ (player.box.avg_three_pt_fg_percent * 100).toFixed(1) + '%' }}
              td(
                @click="dataClick(player, 'ft_m', '罰球命中')",
                :class="[player.box.avg_ftm > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_ftm.toFixed(1) }}
              td(
                @click="dataClick(player, 'ft_a', '罰球出手')",
                :class="[player.box.avg_fta > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_fta.toFixed(1) }}
              td.bdr {{ (player.box.avg_ft_percent * 100).toFixed(1) + '%' }}
              td(
                @click="dataClick(player, 'off_reb', '進攻籃板')",
                :class="[player.box.avg_off_reb > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_off_reb.toFixed(1) }}
              td.bdr(
                @click="dataClick(player, 'def_reb', '防守籃板')",
                :class="[player.box.avg_def_reb > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_def_reb.toFixed(1) }}
              td(
                @click="dataClick(player, 'tov', '失誤')",
                :class="[player.box.avg_tov > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_tov.toFixed(1) }}
              td(
                @click="dataClick(player, 'pf', '犯規')",
                :class="[player.box.avg_pf > 0 ? 'link' : 'gray_out']"
              ) {{ player.box.avg_pf.toFixed(1) }}
              td(:class="[player.box.avg_plus_minus > 0 ? '' : 'gray_out']") {{ player.box.avg_plus_minus.toFixed(1) }}
              td(:class="[player.box.avg_eff > 0 ? '' : 'gray_out']") {{ player.box.avg_eff.toFixed(1) }}
              td(:class="[player.box.avg_at_ratio > 0 ? '' : 'gray_out']") {{ player.box.avg_at_ratio.toFixed(1) }}

  v-dialog.position-relative(
    v-model="isDialogShow",
    style="background: white; height: 100%; width: 810px"
  )
    .close_btn_container
      v-icon.close_btn(@click="isDialogShow = false", large, color="black") mdi-close
    v-container.position-relative.content_container(ref="content")
      v-row(justify="center")
        .uvideo
          youtube(
            ref="youtube",
            @ready="onYTReady",
            @ended="onYTEnd",
            resize,
            fitParent,
            nocookie,
            style="width: 100%; height: 100%; position: absolute; top: 0; left: 0"
          )

          .yt_cover(v-if="coverYT")
            v-btn.play_all.cover(
              @click="playAllClick",
              rounded="",
              color="#487aa4",
              dark,
              v-ripple="false"
            )
              v-icon(left="")
                | mdi-play
              | 播放全部
      v-row(justify="space-between")
        .reference
          //- span.d-block 影片出處：
          //-   a.text_primary(
          //-     href="https://www.youtube.com/c/ssutv/videos",
          //-     target="_blank"
          //-   ) https://www.youtube.com/c/ssutv/videos
          //- span 影片版權皆歸中華民國大專院校體育總會及轉播單位所有
        v-btn.play_all(
          @click="playAllClick",
          rounded="",
          color="#487aa4",
          dark,
          v-ripple="false"
        )
          v-icon(left="")
            | mdi-play
          | 播放全部
      v-row
        span.player_name_team {{ dialogName }} | {{ dialogTeam }}
        .play_table
          .play_head
            .head.play_play
            .head.play_time 時間
            .head.play_player 球員
            .head.play_team 隊伍
            .head.play_event 事件
            .head.play_feedback 問題回報
          .play_content
            .play_item(
              :class="{ selected: currentPlayIndex == index }",
              v-for="(play, index) in playList",
              :key="index"
            )
              .content.play_play
                v-icon.play(x-small, color="white") mdi-play
              .content.play_time {{ play.video_start_at }}
              .content.play_player {{ dialogName }}
              .content.play_team {{ dialogTeam }}
              .content.play_event {{ dialogEvent }}
              .content.play_feedback
                v-icon(small) mdi-alert-octagon
              .play_btn(@click="onPlayItemClick(play, index)")
              .feedback_btn(@click="onFeedbackBtnClick(play)")
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
  dialog-feedback(
    :dialogFeedback="dialogFeedbackShow",
    :play="feedBackPlay",
    @closeFeedbackDialog="closeFeedbackDialog"
  )
  dialog-login(
    :dialogLogin="dialogLoginShow",
    @closeLoginDialog="closeLoginDialog",
    @loginSuccess="loginSuccess"
  )
</template>

<script>
import positionImg from "@/assets/img/position.png";
import StickyColumn from "@/components/common/Table/StickyColumn";
import { getPlayerSeasonData } from "@/api/player";
import { getPlayList } from "@/api/play";
import moment from "moment";
import DialogLoading from "@/components/dialog/DialogLoading";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogFeedback from "@/components/dialog/DialogFeedback";
import DialogLogin from "@/components/dialog/DialogLogin";
import TopHead from "@/components/common/Box/TopHead";

export default {
  name: "LeagueTeamLineUp",
  components: {
    StickyColumn,
    DialogSuccess,
    DialogLoading,
    DialogFeedback,
    DialogLogin,
    TopHead,
  },
  data() {
    return {
      league: this.$route.params.league,
      season: this.$route.params.season,
      teamID: this.$route.params.id,
      playerSeasonBoxData: [],
      currentPlayerSeasonBoxData: [],
      positionImg,
      seasonInquiryType: 0,
      sortValue: {
        type: "",
        order: "",
      },
      isDialogShow: false,
      coverYT: true,
      playHeadList: ["球員", "隊伍", "事件", "時間", "播放"],
      isPlayAll: false,
      playAllIndex: 0,
      currentPlayIndex: null,
      dialogName: "",
      dialogTeam: "",
      dialogEvent: "",
      playList: [],

      feedBackPlay: null,

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },

      dialogFeedbackShow: false,
      dialogLoginShow: false,
    };
  },
  watch: {
    "$route.params.season"() {
      this.season = this.$route.params.season;

      this.playerSeasonBoxData = [];
      this.currentPlayerSeasonBoxData = [];
      this.sortValue = {
        type: "",
        order: "",
      };

      this.getPlayerSeasonData();
    },
    isDialogShow() {
      if (!this.isDialogShow) {
        this.$refs.youtube.player.stopVideo();
        this.dialogName = "";
        this.dialogTeam = "";
        this.dialogEvent = "";
        this.isPlayAll = false;
        this.playAllIndex = 0;
        this.currentPlayIndex = null;
        this.playList = [];
        this.coverYT = true;
      }
    },
  },
  computed: {
    YTPlayer() {
      return this.$refs.youtube.player;
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getPlayerSeasonData();
    },
    async getPlayerSeasonData() {
      let temp = {
        season_id: this.season,
        part: "info,box",
        team_id: this.teamID,
      };
      await getPlayerSeasonData(temp).then((response) => {
        this.playerSeasonBoxData = response.data;
        this.currentPlayerSeasonBoxData = response.data;
      });
    },
    playerClick(player) {
      this.$router.push({
        name: "LeaguePlayer",
        params: {
          season: this.season,
          id: player.info.id,
        },
      });
    },
    sortClick(type, sortData) {
      if (type == this.sortValue.type) {
        if (this.sortValue.order == "DESC") {
          this.sortValue.order = "ASC";
          this.currentPlayerSeasonBoxData = this.sortData(
            this.playerSeasonBoxData,
            type,
            sortData,
            "ASC"
          );
        } else if (this.sortValue.order == "ASC") {
          this.sortValue.type = "";
          this.sortValue.order = "";
          this.currentPlayerSeasonBoxData = this.playerSeasonBoxData;
        }
      } else {
        this.sortValue.type = type;
        this.sortValue.order = "DESC";
        this.currentPlayerSeasonBoxData = this.sortData(
          this.playerSeasonBoxData,
          type,
          sortData,
          "DESC"
        );
      }
    },
    getTableSort(type) {
      if (type == this.sortValue.type) {
        if ("ASC" == this.sortValue.order) {
          return ["fas", "caret-up"];
        } else {
          return ["fas", "caret-down"];
        }
      } else {
        return ["fas", "sort"];
      }
    },
    sortData(allList, sortKey, sortData, order) {
      let tempList = Object.assign([], allList);
      if ("INFO" == sortData) {
        if ("ASC" == order) {
          return tempList.sort(function (a, b) {
            return a.info[sortKey] - b.info[sortKey];
          });
        } else if ("DESC" == order) {
          return tempList.sort(function (a, b) {
            return b.info[sortKey] - a.info[sortKey];
          });
        }
      } else if ("BOX" == sortData) {
        if ("ASC" == order) {
          return tempList.sort(function (a, b) {
            return a.box[sortKey] - b.box[sortKey];
          });
        } else if ("DESC" == order) {
          return tempList.sort(function (a, b) {
            return b.box[sortKey] - a.box[sortKey];
          });
        }
      }
    },
    dataClick(player, stat, event) {
      this.isPlayAll = false;
      this.playList = [];
      this.dialogName = player.info.name;
      this.dialogTeam = player.info.team_name;
      this.dialogEvent = event;
      this.getPlayDataBySeason(player.info.season_player_id, stat);
    },
    getPlayDataBySeason(seasonPlayerId, stat) {
      this.showLoadingDialog("載入中...");

      let params = {
        season_player_id: seasonPlayerId,
        group_by: "PLAYER",
        stat: stat,
      };

      let data = {};

      getPlayList(params, data)
        .then((response) => {
          this.closeLoadingDialog();
          this.playList = response.data;
          if (this.playList && this.playList.length > 0) {
            this.isDialogShow = true;
          }
        })
        .catch(() => {
          this.closeLoadingDialog();
        });
    },
    playAllClick() {
      this.coverYT = false;
      this.isPlayAll = true;
      this.playAllIndex = 0;
      this.currentPlayIndex = this.playAllIndex;
      if (this.playList && this.playList.length > 0) {
        this.playVideo(this.playList[this.playAllIndex]);
      }
    },
    onYTReady() {
      if (this.playList && this.playList.length > 0) {
        this.YTPlayer.cueVideoById({
          videoId: this.playList[0].game__youtube_ids__0,
        });
      }
    },
    onYTEnd(e) {
      if (this.isPlayAll && e.getVideoLoadedFraction() > 0) {
        if (this.playList && this.playList.length > 0) {
          if (this.playAllIndex + 1 < this.playList.length) {
            this.playAllIndex = this.playAllIndex + 1;

            this.currentPlayIndex = this.playAllIndex;

            this.playVideo(this.playList[this.playAllIndex]);
          } else {
            this.isPlayAll = false;
            this.playAllIndex = 0;
          }
        }
      }
    },
    onPlayItemClick(play, index) {
      this.coverYT = false;
      this.currentPlayIndex = index;
      this.playVideo(play);
    },
    onFeedbackBtnClick(play) {
      play.league = this.league;
      play.team = this.dialogTeam;
      this.feedBackPlay = play;
      this.showFeedbackDialog();
      // if (!this.$store.state.user.accessToken) {
      //   this.showLoginDialog();
      // } else {
      //   this.showFeedbackDialog();
      // }
    },
    playVideo(play) {
      this.scrollToTop();
      var startSeconds =
        play.result == "DRAW FOUL"
          ? moment.duration(play.video_end_at).asSeconds()
          : moment.duration(play.video_start_at).asSeconds();
      this.YTPlayer.loadVideoById({
        videoId: play.game__youtube_ids__0,
        startSeconds: startSeconds - play.video_play_duration,
        endSeconds: moment.duration(play.video_start_at).asSeconds() + 2,
      });
    },
    scrollToTop() {
      const c = this.$refs.content.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(this.scrollToTop);
        this.$refs.content.scrollTo(0, c - c / 10);
      }
    },
    getMinutes(temp) {
      let min = parseInt(temp / 60);
      let second = (temp % 60).toFixed(0);
      if (second < 10) {
        return min + ":0" + second;
      }
      return min + ":" + second;
    },
    loginSuccess() {
      this.closeLoginDialog();
    },
    showFeedbackDialog() {
      this.dialogFeedbackShow = true;
    },
    closeFeedbackDialog() {
      this.dialogFeedbackShow = false;
    },
    showLoginDialog() {
      this.dialogLoginShow = true;
    },
    closeLoginDialog() {
      this.dialogLoginShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/dialog_play_video";

a.player {
  color: #3081d3;
  text-decoration: underline;
  display: block;
}

.position_card {
  p.text-subtitle {
    margin-bottom: 0;
  }
}

#sticky-nav {
  table {
    .top_head {
      th {
        top: 0;
      }
    }
    .bottom_head {
      th {
        top: 38px;
      }
    }
  }
}

@media (max-width: 599px) {
  #sticky-nav {
    table {
      .top_head {
        th {
          top: 0;
        }
      }

      .bottom_head {
        th {
          top: 31.69px;
        }
      }
    }
  }
}
</style>
